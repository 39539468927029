import React, { useState } from 'react';
import Tenant from './TenantList';
import { FaHome, FaFileAlt, FaDollarSign, FaTools, FaCalendarAlt, FaClock, FaBars, FaTimes, FaChartBar } from 'react-icons/fa'; // Added FaChartBar
import { motion } from 'framer-motion'; // For animations

const Dashboard = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('dashboard');

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setIsMenuOpen(false);
  };

  const upcomingEvents = [
    {
      id: 1,
      title: 'Annual Tenant Meeting',
      date: 'September 15, 2024',
      time: '3:00 PM',
      icon: <FaCalendarAlt className="text-blue-500 mr-2" />,
    },
    {
      id: 2,
      title: 'Maintenance Schedule',
      date: 'September 18, 2024',
      time: '9:00 AM',
      icon: <FaTools className="text-yellow-500 mr-2" />,
    },
    {
      id: 3,
      title: 'Rent Due Reminder',
      date: 'September 30, 2024',
      time: '11:59 PM',
      icon: <FaDollarSign className="text-green-500 mr-2" />,
    },
  ];

  return (
    <div className="relative min-h-screen bg-gray-50">
      {/* Dropdown Menu Button */}
      <button
        onClick={toggleMenu}
        className="fixed top-4 left-4 z-50 justify-end text-3xl text-gray-700 bg-white p-3 rounded-full shadow-md hover:bg-gray-200 transition-all"
      >
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Dropdown Menu */}
      {isMenuOpen && (
        <motion.div
          className="fixed top-0 left-0 w-64 bg-gray-800 text-white h-full z-40 shadow-lg"
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <nav className="p-4">
            <ul>
              <li className='mt-10'>
                <button
                  onClick={() => handleSectionChange('dashboard')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaHome className="w-6 h-6 mr-3" />
                  <span>Home</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleSectionChange('tenantInfo')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaFileAlt className="w-6 h-6 mr-3" />
                  <span>Tenant Info</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleSectionChange('bills')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaDollarSign className="w-6 h-6 mr-3" />
                  <span>Bills</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleSectionChange('maintenance')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaTools className="w-6 h-6 mr-3" />
                  <span>Maintenance</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleSectionChange('reports')}
                  className="flex items-center p-4 hover:bg-gray-700 w-full text-left transition-colors"
                >
                  <FaChartBar className="w-6 h-6 mr-3" />
                  <span>Reports</span>
                </button>
              </li>
            </ul>
          </nav>
        </motion.div>
      )}

      {/* Main Content */}
      {!isMenuOpen && (
        <div className={`p-8`}>
          {activeSection === 'dashboard' && (
            <>
              <h1 className="text-3xl font-bold text-blue-900 mb-6">Welcome to Arcade Apartments</h1>
              <p className="text-gray-700 mb-6">Explore the dashboard to monitor tenant management, bills, and maintenance.</p>

              {/* Upcoming Events Section */}
              <div className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
                <h2 className="text-2xl font-semibold mb-4 text-center">Upcoming Events</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {upcomingEvents.map((event) => (
                    <motion.div
                      key={event.id}
                      className="flex flex-col items-center bg-gray-100 p-4 rounded-lg shadow hover:shadow-xl transition-all border border-gray-300"
                      whileHover={{ scale: 1.05 }}
                    >
                      <div className="flex items-center mb-3">
                        {event.icon}
                        <h3 className="text-lg font-bold text-gray-800">{event.title}</h3>
                      </div>
                      <div className="text-center">
                        <p className="text-gray-600 mb-1">
                          <FaCalendarAlt className="inline-block text-blue-500 mr-1" />
                          {event.date}
                        </p>
                        <p className="text-gray-600">
                          <FaClock className="inline-block text-gray-500 mr-1" />
                          {event.time}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </>
          )}

          {/* Tenant Info Section */}
          {activeSection === 'tenantInfo' && (
            <>
              <h2 className="text-2xl font-bold mb-6">Tenant Information</h2>
              <Tenant />
            </>
          )}

          {/* Bills Section */}
          {activeSection === 'bills' && (
            <>
              <h2 className="text-2xl font-bold mb-6">Bills Information</h2>
              <p className="text-gray-700">will be available soon.</p>
            </>
          )}

          {/* Maintenance Section */}
          {activeSection === 'maintenance' && (
            <>
              <h2 className="text-2xl font-bold mb-6">Maintenance Requests</h2>
              <p className="text-gray-700">will be available soon.</p>
            </>
          )}

          {/* Reports Section */}
          {activeSection === 'reports' && (
            <>
              <h2 className="text-2xl font-bold mb-6">Reports</h2>
              <p className="text-gray-700"> will be available soon.</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
