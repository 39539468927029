//tenantlist.js

import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';


 export const GET_TENANTS = gql`
  query {
    tenants {
      id
      name
      email
      phone
      houseno
    }
  }
`;

const TenantList = () => {
  const { loading, error, data } = useQuery(GET_TENANTS);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredTenants = data?.tenants.filter((tenant) =>
    tenant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tenant.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tenant.phone.includes(searchTerm) ||
    tenant.houseno.includes(searchTerm)
  ) || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTenants = filteredTenants.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredTenants.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) return <p><ClipLoader color="#4A90E2" size={50} /></p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-6xl mx-auto px-4">
        

        {/* Create Button */}
        <div className="mb-4 text-right">
          <Link to="/create">
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Create New Tenant
            </button>
          </Link>
        </div>

        <section className="bg-white p-6 rounded-lg shadow-md">
          

          <input
            type="text"
            className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            placeholder="Search by name, email, phone, or house no"
            value={searchTerm}
            onChange={handleSearch}
          />

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">Name</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">Email</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">Phone</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium">House No</th>
                  <th className="py-2 px-4 text-center text-gray-600 font-medium" colSpan="3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentTenants.map((tenant) => (
                  <tr key={tenant.id} className="border-t">
                    <td className="py-2 px-4 text-center">{tenant.name}</td>
                    <td className="py-2 px-4 text-center">{tenant.email}</td>
                    <td className="py-2 px-4 text-center">{tenant.phone}</td>
                    <td className="py-2 px-4 text-center">{tenant.houseno}</td>
                    <td className="py-2 px-4 text-center">
                      <Link to={`/view/${tenant.id}`} className="text-blue-600 hover:underline">View</Link>
                    </td>
                    <td className="py-2 px-4 text-center">
                      <Link to={`/edit/${tenant.id}`} className="text-yellow-600 hover:underline">Edit</Link>
                    </td>
                    <td className="py-2 px-4 text-center">
                      <Link to={`/delete/${tenant.id}`} className="text-red-600 hover:underline">Delete</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 flex justify-center">
            <button
              className="px-4 py-2 mx-1 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-4 py-2 mx-1">{currentPage} / {totalPages}</span>
            <button
              className="px-4 py-2 mx-1 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TenantList;
