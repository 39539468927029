import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_TENANTS } from './TenantList'; // Ensure this path is correct

const ADD_TENANT = gql`
    mutation AddTenant($name: String!, $email: String!, $phone: String!, $houseno: String!) {
        addTenant(name: $name, email: $email, phone: $phone, houseno: $houseno) {
            id
            name
            email
            phone
            houseno
        }
    }
`;

const CreateTenant = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [houseno, setHouseno] = useState('');
    const [addTenant, { error }] = useMutation(ADD_TENANT, {
        update(cache, { data: { addTenant } }) {
            const { tenants } = cache.readQuery({ query: GET_TENANTS });
            cache.writeQuery({
                query: GET_TENANTS,
                data: {
                    tenants: tenants.concat([addTenant]),
                },
            });
        },
        onError: (error) => {
            console.error('Error creating tenant:', error.message);
        },
    });
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Ensure all required fields are provided
            if (!name || !email || !phone || !houseno) {
                throw new Error('All fields are required.');
            }

            // Execute the mutation
            await addTenant({ variables: { name, email, phone, houseno } });
            navigate('/');
        } catch (error) {
            console.error('Error creating tenant:', error.message);
        }
    };

    return (
        <div className="p-4 max-w-lg mx-auto">
            <h2 className="text-2xl font-bold mb-4">Create New Tenant</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <input
                    type="text"
                    placeholder="Houseno"
                    value={houseno}
                    onChange={(e) => setHouseno(e.target.value)}
                    className="w-full p-2 border rounded"
                    required
                />
                <button
                    type="submit"
                    className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Create
                </button>
                {error && (
                    <div className="mt-4 text-red-600">
                        Error creating tenant: {error.message}
                    </div>
                )}
            </form>
        </div>
    );
};

export default CreateTenant;
