import React from 'react';

const WhyChooseUs = () => {
  return (
    <section className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold text-gray-800">
          Why Choose <span className="text-blue-900">Nest</span><span className="text-yellow-900">Finder</span>?
        </h2>
        <p className="mt-4 text-gray-600">
          We provide a seamless, all-in-one property management solution tailored to your needs. Here's what sets us apart:
        </p>

        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Benefit 1 */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <div className="flex justify-center">
              {/* Icon */}
              <svg className="h-12 w-12 text-blue-900" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2a10 10 0 00-7.19 17.19A9.95 9.95 0 005 13a9.95 9.95 0 007-2.81 9.95 9.95 0 005 3.19A10 10 0 0012 2zm-4 9a4 4 0 11-4 4 4 4 0 014-4zm8 0a4 4 0 11-4 4 4 4 0 014-4z"/>
              </svg>
            </div>
            <h3 className="mt-4 text-lg font-semibold text-gray-700">Comprehensive Management</h3>
            <p className="mt-2 text-gray-600">
              From tenant management to financial tracking, our platform covers every aspect of property management.
            </p>
          </div>

          {/* Benefit 2 */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <div className="flex justify-center">
              {/* Icon */}
              <svg className="h-12 w-12 text-blue-900" fill="currentColor" viewBox="0 0 24 24">
                <path d="M11 17a1 1 0 01-1-1v-1H6v1a1 1 0 11-2 0v-4a1 1 0 011-1h4v1a1 1 0 002 0V9h4V8a1 1 0 011-1 1 1 0 110 2h-4v6a1 1 0 01-1 1z"/>
              </svg>
            </div>
            <h3 className="mt-4 text-lg font-semibold text-gray-700">User-Friendly Interface</h3>
            <p className="mt-2 text-gray-600">
              Our platform is designed with simplicity in mind, making it easy to navigate and use, even for beginners.
            </p>
          </div>

          {/* Benefit 3 */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
            <div className="flex justify-center">
              {/* Icon */}
              <svg className="h-12 w-12 text-blue-900" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 21a9 9 0 100-18 9 9 0 000 18zm0-4.5A4.5 4.5 0 117.5 12 4.5 4.5 0 0112 16.5zM5.21 6.25a1.12 1.12 0 11.9-2.05 1.12 1.12 0 01-.9 2.05z"/>
              </svg>
            </div>
            <h3 className="mt-4 text-lg font-semibold text-gray-700">Secure & Reliable</h3>
            <p className="mt-2 text-gray-600">
              We prioritize the security and reliability of your data, ensuring your property management process is safe.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
