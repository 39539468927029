// src/App.js
import './App.css';
import Home from './pages/home';

import TenantList from './pages/TenantList';
import UpdateTenant from './pages/UpdateTenant';

import { Route, Routes } from 'react-router-dom';
import ViewTenant from './pages/ViewTenant';
import client from './apollo-client';
import { ApolloProvider } from '@apollo/client';
import ApartmentsList from './pages/apartmentlist';

import Dashboard from './pages/Dashboard';
import DeleteTenant from './pages/DeleteTenant';
import CreateTenant from './pages/CreateTenant';


function App() {
  return (
      <ApolloProvider client={client}>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            
            <Route path="/view/:id" element={<ViewTenant />} />
            <Route path="/list" element={<TenantList />} />
            <Route path="/edit/:id" element={<UpdateTenant />} />
            <Route path="/delete/:id" element={<DeleteTenant />} />
            <Route path="/create" element={<CreateTenant/>} />
            


            
            <Route path="/apartments" element={<ApartmentsList />} />
           
            
            <Route path="/dash" element={<Dashboard />} />
          </Routes>
        </div>
      </ApolloProvider>
  );
}

export default App;
