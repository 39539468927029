import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

// Create an HTTP link to your GraphQL server
const httpLink = createHttpLink({
    uri: 'https://nestfinderhub.africa/backend/graphql', // Update this to your backend URL
});

// Create the Apollo Client instance with the HTTP link and cache
const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

export default client;
