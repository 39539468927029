import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

const GET_TENANT = gql`
  query GetTenant($id: ID!) {
    tenant(id: $id) {
      id
      name
      email
      phone
      
    }
  }
`;

const ViewTenant = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_TENANT, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { tenant } = data;

  return (
    <div className="bg-gray-200 min-h-screen py-12">
      <div className="max-w-6xl mx-auto px-4">
        <h1 className="text-3xl font-semibold text-gray-800 mb-8">Tenant Details</h1>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <p><strong>Name:</strong> {tenant.name}</p>
          <p><strong>Email:</strong> {tenant.email}</p>
          <p><strong>Phone:</strong> {tenant.phone}</p>
          <p><strong>House No:</strong> {tenant.houseno}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewTenant;
