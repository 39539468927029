import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { GET_TENANTS } from './TenantList'; // Ensure this path is correct

const DELETE_TENANT = gql`
    mutation DeleteTenant($id: ID!) {
        deleteTenant(id: $id)
    }
`;

const DeleteTenant = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [deleteTenant] = useMutation(DELETE_TENANT, {
        update(cache) {
            // Read the existing tenants from the cache
            const { tenants } = cache.readQuery({ query: GET_TENANTS });
            // Filter out the deleted tenant
            cache.writeQuery({
                query: GET_TENANTS,
                data: {
                    tenants: tenants.filter(tenant => tenant.id !== id),
                },
            });
        },
        onCompleted: () => {
            console.log('Delete successful');
            navigate('/');
        },
        onError: (error) => {
            console.error('Error deleting tenant:', error.message);
        }
    });

    const handleDelete = () => {
        const confirmed = window.confirm('Are you sure you want to delete this tenant?');
        if (confirmed) {
            deleteTenant({ variables: { id } });
        }
    };

    return (
        <div className="p-4 max-w-lg mx-auto text-center">
            <h2 className="text-2xl font-bold mb-4">Delete Tenant</h2>
            <p className="mb-4">Are you sure you want to delete this tenant?</p>
            <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
                Delete
            </button>
            <button
                onClick={() => navigate('/')}
                className="ml-4 px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
            >
                Cancel
            </button>
        </div>
    );
};

export default DeleteTenant;
