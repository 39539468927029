import React, { useState } from 'react';

const BookAppointment = () => {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="py-12">
      <div className="flex items-center justify-center">
        <div className="bg-white border border-gray-300 rounded-lg shadow-xl p-8 max-w-md text-center transition-transform transform hover:scale-105 hover:shadow-2xl">
          <h2 className="text-3xl font-bold text-blue-900 mb-4">Book Your Appointment</h2>
          <p className="text-gray-600 mb-6">
            Schedule a consultation with us at your convenience
          </p>
          <button
            onClick={handleButtonClick}
            className="bg-blue-900 hover:bg-yellow-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
          >
            Book Appointment
          </button>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-medium text-gray-900">This feature will be available soon!</h3>
            <button
              onClick={handleCloseModal}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookAppointment;
