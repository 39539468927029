import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import image1 from '../assets/images/icon.png';
import profileIcon from '../assets/images/profile.jpg'; 
import settingIcon from '../assets/images/setting.png';
import logIcon from '../assets/images/signout.png';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(prev => !prev);
  };

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  const handleClickOutside = useCallback((event) => {
    if (dropdownOpen && !event.target.closest('.dropdown')) {
      setDropdownOpen(false);
    }
    if (menuOpen && !event.target.closest('.menu')) {
      setMenuOpen(false);
    }
  }, [dropdownOpen, menuOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <nav className="absolute top-0 left-0 right-0 z-20">
      <div className="max-w-6xl mx-auto px-4 py-3 flex items-center justify-between">
        {/* Hamburger Menu for Smaller Screens */}
        <div className="flex items-center md:hidden">
          <button
            onClick={toggleMenu}
            className="menu bg-transparent hover:bg-gray-300 text-white font-semibold py-2 px-4 rounded inline-flex items-center transition duration-300"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
          <div>
          {menuOpen && (
            <ul className="absolute left-0 mt-9 bg-white border rounded-lg shadow-lg w-48 transition duration-300 ease-in-out transform origin-top-left z-50">
              <li className="border-b last:border-b-0">
                <a
                  href="#coverbanner"
                  className="block px-4 py-2 hover:bg-gray-100 transition duration-150"
                >
                  Home
                </a>
              </li>
              <li className="border-b last:border-b-0">
                <a
                  href="#content"
                  className="block px-4 py-2 hover:bg-gray-100 transition duration-150"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="#footer"
                  className="block px-4 py-2 hover:bg-gray-100 transition duration-150"
                >
                  Contact
                </a>
              </li>
            </ul>
          )}
          </div>
        </div>
        
        {/* Logo and Title */}
        <div className="flex items-center">
          <img src={image1} alt="Logo" className="h-9 w-9 rounded-full object-cover" />
          <h1 className="ml-2 text-4xl font-semibold text-white">
            <span className="text-blue-800">Nest</span>
            <span className="text-yellow-900">Finder</span>
          </h1>
        </div>
        
        {/* Navigation Links for Larger Screens */}
        <div className="hidden md:flex space-x-4 items-center ml-auto">
          <a
            href="#coverbanner"
            className="text-white hover:text-gray-300"
          >
            Home
          </a>
          <a
            href="#content"
            className="text-white hover:text-gray-300"
          >
            Services
          </a>
          <a
            href="#footer"
            className="text-white hover:text-gray-300"
          >
            Contact
          </a>
        </div>
        
        {/* Notification and Profile Icons */}
        <div className="flex items-center">
          <svg
            className="h-6 w-6 mx-2 cursor-pointer text-white hover:text-gray-300 transition duration-150"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405C18.295 14.21 18 13.11 18 12V8c0-3.11-1.34-4-3-4s-3 .89-3 4v4c0 1.11-.29 2.21-.59 3.595L9 17h5M7 17h.01M10 21h4M12 22a2 2 0 11-4 0h4a2 2 0 11-4 0" />
          </svg>
          <div className="relative dropdown z-50">
            <button
              onClick={toggleDropdown}
              className="bg-transparent hover:bg-gray-300 text-white font-semibold py-2 px-4 rounded inline-flex items-center transition duration-300"
            >
              <img src={profileIcon} alt="Profile" className="h-8 w-8 rounded-full object-cover" />
            </button>
            {dropdownOpen && (
              <ul className="absolute right-0 mt-2 bg-white border rounded-lg shadow-lg w-56 transition duration-300 ease-in-out transform origin-top-right">
                <li className="border-b last:border-b-0">
                  <Link to="" className="flex items-center px-4 py-3 hover:bg-gray-100 transition duration-150">
                    <img src={settingIcon} alt="Icon" className="w-5 h-5 mr-3 rounded-full object-cover" />
                    <span className="text-gray-700">Settings</span>
                  </Link>
                </li>
                <li className="border-b last:border-b-0">
                  <Link to="" className="flex items-center px-4 py-3 hover:bg-gray-100 transition duration-150">
                    <img src={logIcon} alt="Icon" className="w-5 h-5 mr-3 rounded-full object-cover" />
                    <span className="text-gray-700">Logout</span>
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
