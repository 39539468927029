import React from 'react';
import backgroundImage from '../assets/images/house1.webp'; // Adjust the path as needed

const Coverbanner = () => {
  return (
    <section
      className="relative bg-cover bg-center h-screen flex items-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div id="coverbanner" className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 max-w-2xl text-left text-white px-8 md:px-16">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">
          Your Real Estate, Our Expertise
        </h1>
        <p className="text-lg md:text-2xl mb-8">
          Transform the way you manage your properties with our cutting-edge
          platform. From tenant tracking to maintenance requests, we make
          property management a breeze—leaving you more time to grow your
          investments.
        </p>
        <a
          href="#content"
          className="bg-blue-900 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
        >
          Discover More
        </a>
      </div>
    </section>
  );
};

export default Coverbanner;
