import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { FaBuilding, FaSun, FaWater } from 'react-icons/fa';

const ApartmentsList = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  const apartments = [
    { id: 1, name: 'Arcade Apartments (Demo)', icon: <FaBuilding />, route: '/dash' },
    { id: 2, name: 'Sunset Apartments', icon: <FaSun />, route: '' },
    { id: 3, name: 'Riverview Apartments', icon: <FaWater />, route: '' },
  ];

  const handleClick = (route, name) => {
    if (route) {
      navigate(route);
    } else {
      setAlertMessage(`${name} not available for view.`);
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-gray-50">
      <div className="text-center">
        <h2 className="text-3xl font-extrabold text-blue-900 mb-6">Choose Your Apartment</h2>
        <p className="text-lg text-gray-600 mb-10">
          Explore our available apartments and select the one that suits you best.
        </p>
      </div>
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {apartments.map((apartment) => (
          <li
            key={apartment.id}
            className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 cursor-pointer"
            onClick={() => handleClick(apartment.route, apartment.name)}
          >
            <div className="flex items-center space-x-4">
              <div className="text-blue-900 text-3xl">{apartment.icon}</div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 hover:underline">
                  {apartment.name}
                </h3>
                <p className="mt-1 text-gray-600">Click to view details</p>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {alertMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-medium text-gray-900">{alertMessage}</h3>
            <button
              onClick={handleCloseAlert}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApartmentsList;
